import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  max-height: 100vh;
  width: 100%;

  .card-section {
    width: 100%;
    margin: 0;
    max-height: 100vh;
    max-width: 100%;
    aspect-ratio: 16/ 6;
    min-height: 100%;

    &.video {
      aspect-ratio: 16/9;
    }

    ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
      aspect-ratio: 9/10;
      min-height: 445px;
    }

    .card-content {
      /* max-width: 970px; */
      max-width: 1400px;
      justify-content: flex-start;
    }

    .card-background {
      img {
        object-position: top;
      }
    }

    .card-title {
      padding-top: 120px;
      ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
        padding-top: 90px;
        position: relative;
        z-index: 10;
      }
    }

    .card-description {
      bottom: 40px;
      z-index: 10;
    }
  }
`

export const Image = styled.img``
